body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.invoice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow: auto; /* Allow vertical and horizontal scrolling */
}

.form-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
}

.invoice-form {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}

.invoice-preview-container {
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  flex: 1.2;
}

.invoice-form-content {
  padding: 10px;
}

.invoice-preview {
  width: 210mm;
  height: 297mm;
}

.invoice {
  width: 210mm;
  height: 297mm;
  position: relative;
  background: white;
  overflow: hidden;
  font-size: 12px; /* Adjust font size for better readability */
}

.invoice-header,
.invoice-footer {
  position: absolute;
  width: 100%;
}

.invoice-header {
  top: 0;
}

.invoice-footer {
  bottom: -1mm;
}

.invoice-header img,
.invoice-footer img {
  width: 100%;
}

.invoice-body {
  padding: 20mm 10mm;
  box-sizing: border-box;
  margin-top: 60mm;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1mm;
}

table,
th,
td {
  border: 1px solid #cccccc;
}

th,
td {
  padding: 10px;
  text-align: left;
}

h1,
h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 1em;
}

.form-control {
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
}

.item-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.item-table th,
.item-table td {
  border: 1px solid #cccccc;
  padding: 0.5em;
}

.secondary {
  background-color: red;
  color: white;
  border: none;
  padding: 0.5em;
  cursor: pointer;
}

.add-item-button {
  width: 100%;
  padding: 0.5em;
  background-color: green;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-button {
  margin-top: 1em;
  padding: 0.5em;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

.download-button {
  margin-top: 2em;
  padding: 1em;
  background-color: orange;
  color: white;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.contact-table {
  border: 0px;
}

.contact-table-no-border {
  border: 0px;
  /* decrese line height */
  line-height: 0.1;
}

.contact-table-align-right {
  text-align: right;
}

.align-right {
    text-align: right;
}

.notes-section {
  margin-top: 20mm;
}

.terms-and-conditions-section {
  margin-top: 10mm;
}

.box-with-radius {
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100%;
  min-height: 10mm;
  margin-top: 2mm;
}

.box-with-radius-content {
  padding: 1mm 4mm;
  height: 100%;
}
